<template>
  <div class="news-container">
    <div class="new-content">
      <div class="title">{{ newsDetail.title }}</div>
      <div class="time">{{ newsDetail.updateTime }}</div>
      <div class="content" v-html="newsDetail.content"></div>
      <div class="bt-line">
        <div class="bt-back" @click="toList">返回列表</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getNewsDetailById } from "@/api/news/news";
export default {
  name: "newsDetail",
  data() {
    return {
      id: this.$route.query.id,
      newsDetail: {},
    };
  },
  mounted() {
    this.getNewsDetail();
  },
  methods: {
    getNewsDetail() {
      getNewsDetailById({ Id: this.id }).then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          this.newsDetail = res.data.data;
          this.newsDetail.updateTime = this.newsDetail.updateTime.replace(
            "T",
            "  "
          );
        }
      });
    },
    toList() {
      this.$router.push({
        path: "/news",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.news-container {
  background: #f4f4f4;
  width: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  .new-content {
    background: #fff;
    flex: 1;
    .content {
      width: 1000px;
      min-height: 100%;
      padding: 0px 60px 30px 60px;
    }
    .time {
      text-align: center;
      margin-bottom: 30px;
      font-size: 18px;
    }
    .title {
      font-size: 28px;
      font-weight: 600;
      text-align: center;
      /* line-height: 40px; */
      margin-top: 40px;
      margin-bottom: 30px;
    }
    .bt-line {
      width: 100%;
      padding-bottom: 40px;
      display: flex;
      justify-content: center;
    }
    .bt-back {
      width: 124px;
      height: 44px;
      background: #6d5efe;
      border-radius: 22px;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      line-height: 44px;
      text-align: center;
      margin-left: 20px;
      letter-spacing: 2px;
    }
  }
}
</style>
